<form class="flex flex-col w-full d"
      [formGroup]="dateForm">
    <mat-form-field class="w-full" (click)="picker.open()">
        <mat-label>Date/Time</mat-label>
        <input matInput [ngxMatDatetimePicker]="picker"
               placeholder="Choose a date"
               [formControlName]="'timer_expires_at'"
               (dateChange)="changeFormatDate($event)"
               [min]="currentDate"
               readonly>
        <mat-datepicker-toggle matSuffix
                               [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker
                                 [showSeconds]="true" [color]="'primary'">
        </ngx-mat-datetime-picker>
        <mat-error *ngIf="dateForm.get('timer_expires_at').invalid">
            {{_formUtilsService.getErrorMessage(dateForm, 'timer_expires_at')}}
        </mat-error>
    </mat-form-field>
</form>

