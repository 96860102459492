// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    clientId: '91426d55-f23b-4198-82e8-ba3bc322d44f',
    clientSecret: 'Kg0gh8umKhDjZZjqT8UfgqYmHURuaD6UCRdBvPTz',
    apiUrl: 'https://network.payments.dev.zenzenzen.net/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
