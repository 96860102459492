import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {CommonModule} from '@angular/common';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {MatMomentDateModule} from '@angular/material-moment-adapter';

import {FilterComponent} from './filter.component';

import * as moment from 'moment';
import {CustomSelectModule} from '../custom-select/custom-select.module';

@NgModule({
    declarations: [
        FilterComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatMomentDateModule,
        CustomSelectModule
    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                display: {
                    dateInput: 'MM/DD/yyyy',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                }
            }
        }
    ],
    exports: [
        FilterComponent
    ]
})
export class FilterModule {
}
