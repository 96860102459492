import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

    @Input() filterForm;
    @Output() filter = new EventEmitter();

    types: string[] = ['register', 'text', 'payment'];
    statuses: string[] = ['failed', 'refunded', 'paid', 'unpaid', 'declined'];

    constructor() {
    }

    ngOnInit(): void {
    }

    search(): void {
        this.filter.emit(this.filterForm.value);
    }

    clearFilter(): void {
        this.filterForm.reset();
        // this.filter.emit(this.filterForm.value);
    }

    closeFilter(): void {
        this.filterForm.reset();
        this.filter.emit({closeFilter: true});
    }
}
