import {
    Component,
    Input,
    OnInit,
    EventEmitter,
    ChangeDetectorRef, ViewChild, Output,
} from '@angular/core';
import {FormUtilsService} from '../../../core/utils/form-utils.service';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';

@Component({
    selector: 'app-custom-select',
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit {

    @Input() selectForm;
    @Input() formField: string;
    @Input() label: string;
    @Input() multi: boolean;
    @Input() items: any;
    @Output() changed?: any = new EventEmitter();

    // @Input() checkValidation = new EventEmitter();

    allSelected = false;
    search = '';
    selectedItems: any[] = [];

    @ViewChild('matSelectMulti') matSelMulti: MatSelect;

    constructor(public _formUtilsService: FormUtilsService,
                private _changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.search = '';
        this.selectedItems = Object.assign([], this.items);
    }

    opened(isOpen: boolean): void {
        if (!isOpen) {
            this.search = '';
            this.selectedItems = Object.assign([], this.items);
        }
    }

    // checkValidationPicker(): void {
    //     this.selectForm.get(this.formField).markAsTouched();
    //     this.selectForm.get(this.formField).updateValueAndValidity();
    //     this._changeDetectorRef.markForCheck();
    // }

    toggleAllSelection(): any {
        this.allSelected = !this.allSelected;  // to control select-unselect
        const items = [];
        if (this.allSelected) {
            this.matSelMulti.options.forEach((item: MatOption) => {
                if (item.value || item.value === 0) {
                    items.push(item.value);
                }
            });
        }
        this.selectForm.get(this.formField).setValue(items);
        this._changeDetectorRef.markForCheck();
    }

    filterSelect(event: any): void {
        this.selectedItems = this._formUtilsService.selectFilter(this.items, event);
    }

    getValue(item): any {
        return item.id ? item.id : item;
    }

    checkedData(event: any): void {
        if (this.changed) {
            this.changed.emit(event);
        }
    }
}
