import {NgModule} from '@angular/core';
import {LayoutComponent} from 'app/layout/layout.component';
import {EmptyLayoutModule} from 'app/layout/layouts/empty/empty.module';
import {ModernLayoutModule} from 'app/layout/layouts/horizontal/modern/modern.module';
import {DenseLayoutModule} from 'app/layout/layouts/vertical/dense/dense.module';
import {SharedModule} from 'app/shared/shared.module';
import {MaterialLayoutModule} from './layouts/horizontal/material/material.module';

const modules = [
    // Empty
    EmptyLayoutModule,
    // Horizontal navigation
    MaterialLayoutModule,
    ModernLayoutModule,

    // Vertical navigation
    DenseLayoutModule,
];

@NgModule({
    declarations: [
        LayoutComponent,
    ],
    imports: [
        SharedModule,
        ...modules
    ],
    exports: [
        ...modules
    ]
})
export class LayoutModule {
}
