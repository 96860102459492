/** Angular */
import {ChangeDetectorRef, Injectable} from '@angular/core';

import {FormGroup} from '@angular/forms';

@Injectable()
export class FormUtilsService {

    /**
     * Checking control validation
     *
     * @param form
     * @param controlName: string => Equals to formControlName
     * @param validationType: string => Equals to valitors name
     */
    public isControlHasError(form: FormGroup, controlName: string, validationType: string): boolean {
        const control = form.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    getErrorMessage(form: FormGroup, controlName: string, minLength: number = 3, maxlength: number = 320): any {
        const control = form.controls[controlName];
        if (control.hasError('minlength')) {
            return 'Minimum field length: ' + minLength;
        }
        if (control.hasError('maxlength')) {
            return 'Maximum field length: ' + maxlength;
        }
        if (control.hasError('email')) {
            return 'Not a valid email';
        }
        if (control.hasError('validatePhoneNumber')) {
            return 'Not a valid phone number';
        }
        if (control.hasError('mustMatch')) {
            return 'New password and confirm new password do not match';
        }
        if (control.hasError('serverError')) {
            return control.getError('serverError');
        }
        return control.hasError('required') ? 'You must enter a value' : '';
    }

    prepareServerError(response: any, form: FormGroup, cdr: ChangeDetectorRef): void {
        if (response.error.errors) {
            Object.keys(response.error.errors).map(field => {
                let error = '';
                if (response.error.errors[field] && response.error.errors[field].length) {
                    response.error.errors[field].map(text => {
                        error += text;
                    });
                }
                form.controls[field].setErrors({'serverError': error});
            });
            // Mark for check
            cdr.markForCheck();
            return this.getMessage(response.error.message);
        } else {
            // Show the error message
            return this.getMessage(response.error);
        }
    }

    getMessage(message: string, type: string = 'error'): any {
        return {
            appearance: 'outline',
            content: message,
            shake: true,
            showIcon: false,
            type: type
        };
    }

    selectFilter(selectArray: any, value: string): string[] {
        const filterValue = this.normalizeValue(value);
        return selectArray.filter(item => {
            const itemValue = item.name ? item.name : item;
            return this.normalizeValue(itemValue).includes(filterValue);
        });
    }

    normalizeValue(value: string): string {
        return value.toLowerCase().replace(/\s/g, '');
    }

}
