<form class="flex flex-col w-full d"
      [formGroup]="selectForm">
    <mat-form-field class="w-full">
        <mat-label>{{label}}</mat-label>
        <mat-select required [multiple]="multi" #matSelectMulti
                    [formControlName]="formField"  (openedChange)="opened($event)"
                    (selectionChange)="checkedData($event.value)">
            <input placeholder="Search" class="select-search" type="text"
                   [(ngModel)]="search" [ngModelOptions]="{standalone: true}"
                   (ngModelChange)="filterSelect($event)">
            <mat-checkbox value="0" (click)="toggleAllSelection()" *ngIf="multi && selectedItems.length">Select all
            </mat-checkbox>
            <mat-option *ngFor="let _item of selectedItems;"
                        [value]="getValue(_item)">
                {{ _item.name ? _item.name : _item.label ? _item.label : _item }}
            </mat-option>
            <p class="h-10 text-center leading-10" *ngIf="!selectedItems || !selectedItems.length">Nothing found!</p>
        </mat-select>
        <mat-error *ngIf="selectForm.get(formField).invalid && selectForm.get(formField).touched">
            {{_formUtilsService.getErrorMessage(selectForm, formField)}}
        </mat-error>
    </mat-form-field>
</form>
