export const PERMISSIONS: any = {
    USERS: {
        VIEW: 'view users',
        CREATE: 'create users',
        UPDATE: 'update users',
        DELETE: 'delete users',
        VIEW_ROLES: 'view user roles'
    },
    ROLES: {
        VIEW: 'view roles',
        CREATE: 'create roles',
        UPDATE: 'update roles',
        DELETE: 'delete roles',
        VIEW_USERS: 'view role users',
        VIEW_PERMISSIONS: 'view role permissions',
    },
    SITES: {
        VIEW: 'view sites',
        CREATE: 'create sites',
        UPDATE: 'update sites',
        DELETE: 'delete sites',
    },
    PACKAGES: {
        VIEW: 'view packages',
        CREATE: 'create packages',
        UPDATE: 'update packages',
        DELETE: 'delete packages',
    },
    METHODS: {
        VIEW: 'view methods',
        VIEW_RELATED: 'view related methods',
        CREATE: 'create methods',
        UPDATE: 'update methods',
        DELETE: 'delete methods',
    },
    CONDITIONS: {
        VIEW: 'view conditions',
        CREATE: 'create conditions',
        UPDATE: 'update conditions',
        DELETE: 'delete conditions',
    },
    FORMS: {
        VIEW: 'view forms',
        CREATE: 'create forms',
        UPDATE: 'update forms',
        DELETE: 'delete forms',
    },
    PAGES: {
        VIEW: 'view pages',
        CREATE: 'create pages',
        UPDATE: 'update pages',
        DELETE: 'delete pages',
    },
    PAYMENTS: {
        VIEW: 'view payments',
        CREATE: 'create payments',
        UPDATE: 'update payments',
        DELETE: 'delete payments',
    },
    CREDITCARDS: {
        VIEW: 'view creditcards',
        CREATE: 'create creditcards',
        UPDATE: 'update creditcards',
        DELETE: 'delete creditcards',
    },
    CONFIGS: {
        VIEW: 'view configs',
    },
    GRAND: 'grand',
    ME: 'me',
};
