/** Angular */
import {Injectable} from '@angular/core';

import {FormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

import moment from 'moment';
import {HttpParams} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';

import {ActionNotificationComponent} from '../../shared/components/action-natification/action-notification.component';

export enum MessageType {
    Error,
    Success,
    Create,
    Update,
    Delete,
    Read,
}

@Injectable()
export class TableUtilsService {
    filter: any;

    constructor(private _snackBar: MatSnackBar) {
    }

    /** get query params */
    getQueryParams(paginator?: MatPaginator, sort?: any, params?: any, filterValue?: any): any {
        this.filter = {...params, ...{}};
        if (sort) {
            // this.filter.sortField = sort.active;
            // this.filter.sortOrder = sort.direction;

            this.filter[sort.active + '[orderBy]'] = sort.direction;
        }

        if (paginator) {
            this.filter.per_page = paginator.pageSize;
            let pi = paginator.pageIndex;
            this.filter.page = ++pi;
        }
        if (filterValue) {
            this.checkSearchField(this.filter, filterValue);
        }

        return this.getFindHTTPParams(this.filter);
    }

    /**
     * Prepare query http params
     * @param queryParams: QueryParamsModel
     */
    getFindHTTPParams(queryParams): HttpParams {
        if (queryParams) {
            const params = new HttpParams({fromObject: queryParams});
            return params;
        }
        return new HttpParams();
    }

    // check filter field
    checkSearchField(_filter: any, filterValue: any): void {
        Object.keys(filterValue).map(field => {
            if (filterValue[field]) {
                field === 'created_at' ?
                    _filter[field] = moment(filterValue[field]).format('YYYY-MM-DD') :
                    (field === 'updated_at') ?
                        _filter[field] = moment(filterValue[field]).format('YYYY-MM-DD') :
                        (field === 'online_at') ?
                            _filter[field] = moment(filterValue[field]).format('YYYY-MM-DD') :
                            _filter[field] = filterValue[field];
            }
        });
    }

    /**
     * Showing (Mat-Snackbar) Notification
     *
     * @param _message
     * @param _type
     * @param _duration
     * @param _showCloseButton
     * @param _showUndoButton
     * @param _verticalPosition
     * @param _undoButtonDuration
     */
    showActionNotification(
        _message: string,
        _type: MessageType = MessageType.Success,
        _duration: number = 3000,
        _showCloseButton: boolean = false,
        _showUndoButton: boolean = false,
        _undoButtonDuration: number = 3000,
        _verticalPosition: 'top' | 'bottom' = 'top'
    ): any {
        const _data = {
            message: _message,
            snackBar: this._snackBar,
            showCloseButton: _showCloseButton,
            showUndoButton: _showUndoButton,
            undoButtonDuration: _undoButtonDuration,
            verticalPosition: _verticalPosition,
            type: _type,
            action: 'Undo'
        };
        return this._snackBar.openFromComponent(ActionNotificationComponent, {
            duration: _duration,
            data: _data,
            verticalPosition: _verticalPosition
        });
    }

    /**
     * Returns CSS Class by status
     *
     * @param status: number
     */
    getItemCssClassByStatus(status: string = 'disabled'): string {
        switch (status) {
            case 'active':
                return 'green';
            case 'disabled':
                return 'gray';
            case 'paid':
                return 'green';
            case 'unpaid':
                return 'red';
            case 'refunded':
                return 'red-700';
        }
        return '';
    }

}
