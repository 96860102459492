import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';

import {environment} from '../../../environments/environment';

@Injectable()
export class Interceptor implements HttpInterceptor {
    /**
     * Constructor
     *
     * @param {AuthService} _authService
     */
    constructor(
        private _authService: AuthService
    ) {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const clientId = localStorage.getItem('clientId') || environment.clientId;

        const headerConfig = {
            'x-api-key': clientId,
        };

        if (this._authService.accessToken) {
            headerConfig['Authorization'] = 'Bearer ' + this._authService.accessToken;
        }

        req = req.clone({
            setHeaders: headerConfig
        });

        return next.handle(req).pipe(
            catchError((error) => {

                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // Sign out
                    this._authService.signOut();

                    // Reload the app
                    location.reload();
                }

                return throwError(error);
            })
        );
    }
}
