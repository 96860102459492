import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FilterModule} from './components/filter/filter.module';
import {DateTimePickerModule} from './components/datetimepicker/date-time-picker.module';
import {CustomSelectModule} from './components/custom-select/custom-select.module';
import {ActionNotificationModule} from './components/action-natification/action-notification.module';
import {BackButtonModule} from './components/back-button/back-button.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FilterModule,
        DateTimePickerModule,
        CustomSelectModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FilterModule,
        DateTimePickerModule,
        CustomSelectModule,
        ActionNotificationModule,
        BackButtonModule
    ]
})
export class SharedModule {
}
