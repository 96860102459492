<!-- Navigation -->
<treo-vertical-navigation class="theme-dark"
                          [appearance]="navigationAppearance"
                          [mode]="isScreenSmall ? 'over' : 'side'"
                          [name]="'mainNavigation'"
                          [navigation]="data.navigation.default"
                          [opened]="!isScreenSmall">

    <div treoVerticalNavigationContentHeader>
        <div class="logo">
            <img src="assets/images/logo/logo_transparent.png">
        </div>
    </div>

</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">

    <!-- Header -->
    <div class="header">

        <!-- Navigation toggle button -->
        <button class="navigation-toggle-button"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>

        <!-- Navigation appearance toggle button -->
        <button class="navigation-appearance-toggle-button"
                [ngClass]="{mirror: navigationAppearance === 'dense'}"
                mat-icon-button
                (click)="toggleNavigationAppearance()">
            <mat-icon [svgIcon]="'menu_open'"></mat-icon>
        </button>

        <!-- Spacer -->
        <div class="spacer"></div>

        <!-- Notifications -->
        <notifications [notifications]="data.notifications"></notifications>

        <!-- User -->
        <user></user>

    </div>

    <!-- Content -->
    <div class="content">
        <mat-progress-bar [mode]="'indeterminate'" value="40" *ngIf="loading"></mat-progress-bar>
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

    <!-- Footer -->
    <div class="footer">
        Treo &copy; {{currentYear}}
    </div>

</div>
