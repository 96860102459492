import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {RouteUtilsService} from '../../../core/utils/route-utils.service';

@Component({
    selector: 'app-back-button',
    templateUrl: './back-button.component.html',
    styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit, OnDestroy {

    returnUrl: string | null = null;

    _unsubscribeAll = new Subject();

    constructor(public _router: Router,
                private _route: ActivatedRoute,
                private _routeUtilsService: RouteUtilsService) {
    }

    ngOnInit(): void {
        this._route.queryParams.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(params => {
            if (params && params['returnUrl']) {
                this.returnUrl = params['returnUrl'];
            } else {
                this.returnUrl = null;
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    back(): void {
        this._routeUtilsService.back(this.returnUrl);
    }
}
