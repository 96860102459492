import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    ChangeDetectionStrategy
} from '@angular/core';
import {FormUtilsService} from '../../../core/utils/form-utils.service';
import moment from 'moment';

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {

    @Input() dateForm;
    @Input() disableDatePast: boolean;

    @Input() checkValidation = new EventEmitter();

    // @Output() onChange = new EventEmitter();

    currentDate = new Date();

    constructor(public _formUtilsService: FormUtilsService,
                private _changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    changeFormatDate(event: any): void {
        this.dateForm.get('timer_expires_at').setValue(moment(event.value).format('YYYY-MM-DD hh:mm:ss'));
    }

    checkValidationPicker(): void {
        this.dateForm.get('timer_expires_at').markAsTouched();
        this.dateForm.get('timer_expires_at').updateValueAndValidity();
        this._changeDetectorRef.markForCheck();
    }

}
