import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {AuthUtils} from 'app/core/auth/auth.utils';

import {environment} from '../../../environments/environment';
import {NgxPermissionsService} from "ngx-permissions";

@Injectable()
export class AuthService {
    // Private
    private _authenticated: boolean;

    private url: string = environment.apiUrl;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param permissionsService
     */
    constructor(
        private _httpClient: HttpClient,
        private permissionsService: NgxPermissionsService
    ) {
        // Set the defaults
        this._authenticated = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('access_token', token);
    }

    get accessToken(): string {
        return localStorage.getItem('access_token');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { username: string, password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(this.url + 'api/login', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.access_token;

                // Set the authenticated flag to true
                this._authenticated = true;
                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signUp(credentials: any): Observable<any> {

        return this._httpClient.post(this.url + 'api/register?exc=1', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.access_token;

                // Set the authenticated flag to true
                this._authenticated = true;
                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * get permissions
     */
    getPermissions(): any {
        return this._httpClient.get<any>(this.url + 'api/profile/permissions').pipe(
            map(res => {
                this.permissionsService.loadPermissions(res.data);
                return res.data;
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('access_token');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (this.accessToken && this.accessToken.length) {
            this._authenticated = true;
            this.getPermissions().subscribe();
            return of(true);
        }

        return of(false);
    }
}
