import { Layout } from 'app/layout/layout.types';

import {PERMISSIONS} from '../../shared/contstants/permissions.const';

// Theme type
export type Theme = 'auto' | 'dark' | 'light';

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig
{
    layout: Layout;
    theme: Theme;
}

/**
 * Default configuration for the entire application. This object is used by
 * 'ConfigService' to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * 'ConfigService'.
 */
export const appConfig: AppConfig = {
    layout: 'dense',
    theme : 'light'
};

export const defaultNavigation: any[] = [
    {
        id: 'starter',
        title: 'Network',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'main',
                title: 'Main',
                type: 'basic',
                icon: 'mat_outline:dashboard',
                link: '/example',
            },
            {
                id: 'users',
                title: 'Users',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'main/users',
                permission: PERMISSIONS.USERS.VIEW
            },
            {
                id: 'roles',
                title: 'Roles',
                type: 'basic',
                icon: 'heroicons_outline:user-circle',
                link: 'main/roles',
                permission: PERMISSIONS.ROLES.VIEW
            },
            {
                id: 'sites',
                title: 'Sites',
                type: 'basic',
                icon: 'mat_outline:language',
                link: 'main/sites',
                permission: PERMISSIONS.SITES.VIEW
            },
            {
                id: 'packages',
                title: 'Packages',
                type: 'basic',
                icon: 'mat_outline:web',
                link: 'main/packages',
                permission: PERMISSIONS.PACKAGES.VIEW
            },
            {
                id: 'pages',
                title: 'Pages',
                type: 'basic',
                icon: 'mat_outline:list',
                link: 'main/pages',
                permission: PERMISSIONS.PAGES.VIEW
            },
            {
                id: 'payments',
                title: 'Payments',
                type: 'basic',
                icon: 'mat_outline:list',
                link: 'main/payments',
                permission: PERMISSIONS.PAYMENTS.VIEW
            },
            {
                id: 'agents',
                title: 'Agents',
                type: 'basic',
                icon: 'heroicons_outline:user-circle',
                link: 'main/agents',
                permission: PERMISSIONS.USERS.VIEW
            },
            {
                id: 'configs',
                title: 'Configs',
                type: 'basic',
                icon: 'mat_outline:list',
                link: 'main/configs'
            }
        ]
    }
];
export const horizontalNavigation: any[] = [
    // {
    //     id: 'me-dashboard',
    //     title: 'Dashboard',
    //     type: 'basic',
    //     icon: 'apps',
    //     link: 'app-pages/dashboard'
    // }, {
    //     id: 'me-packages',
    //     title: 'Packages',
    //     type: 'basic',
    //     icon: 'mat_outline:web',
    //     link: 'me-packages',
    // },
    // {
    //     id: 'me-payments',
    //     title: 'Payments',
    //     type: 'basic',
    //     icon: 'mat_outline:list',
    //     link: 'app-pages/me-payments'
    // },
    // {
    //     id: 'profile',
    //     title: 'Profile',
    //     type: 'basic',
    //     icon: 'heroicons_outline:user-circle',
    //     link: 'profile'
    // },
    {
        id: 'networks',
        title: 'Networks',
        type: 'basic',
        icon: 'heroicons_outline:briefcase',
        link: 'backend/networks'
    },
    {
        id: 'roles',
        title: 'Roles',
        type: 'basic',
        icon: 'heroicons_outline:adjustments',
        link: 'backend/roles'
    },
    {
        id: 'users',
        title: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:user-circle',
        link: 'backend/users'
    },
    {
        id: 'accounts',
        title: 'Accounts',
        type: 'basic',
        icon: 'mat_outline:account_box',
        link: 'backend/accounts'
    },
    // {
    //     id: 'activities',
    //     title: 'Activities',
    //     type: 'basic',
    //     icon: 'heroicons_outline:collection',
    //     link: 'backend/activities'
    // },
    // {
    //     id: 'statistics',
    //     title: 'Statistics',
    //     type: 'basic',
    //     icon: 'heroicons_outline:chart-bar',
    //     link: 'backend/statistics'
    // },
    // {
    //     id: 'exceptions',
    //     title: 'Exceptions',
    //     type: 'basic',
    //     icon: 'heroicons_outline:ban',
    //     link: 'backend/exceptions'
    // },
    // {
    //     id: 'logs',
    //     title: 'Logs',
    //     type: 'basic',
    //     icon: 'mat_outline:explore',
    //     link: 'backend/logs'
    // },
    {
        id: 'social-profiles',
        title: 'Social profiles',
        type: 'basic',
        icon: 'mat_outline:explore',
        link: 'backend/social-profiles'
    },
];
