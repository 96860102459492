import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {CustomSelectComponent} from './custom-select.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from "@angular/material/checkbox";

@NgModule({
    declarations: [
        CustomSelectComponent
    ],
    imports: [
        CommonModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatSelectModule,
        MatCheckboxModule
    ],
    providers: [
    ],
    exports: [
        CustomSelectComponent
    ]
})
export class CustomSelectModule {
}
