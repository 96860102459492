<div class="filter">
    <form class="flex flex-col w-full"
          [formGroup]="filterForm">

        <div class="flex flex-col p-8">

            <div class="flex flex-auto flex-wrap">
                <ng-container *ngIf="filterForm.get('id')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>ID</mat-label>
                            <input matInput
                                   [formControlName]="'id'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('hash')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Hash</mat-label>
                            <input matInput
                                   [formControlName]="'hash'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('uuid')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>UUID</mat-label>
                            <input matInput
                                   [formControlName]="'uuid'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('user_id')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>User ID</mat-label>
                            <input matInput
                                   [formControlName]="'user_id'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('network_user_id')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>User ID</mat-label>
                            <input matInput
                                   [formControlName]="'network_user_id'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('customer_status')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Status</mat-label>
                            <mat-select [formControlName]="'customer_status'" placeholder="Select status" value="">
                                <mat-option value="">All</mat-option>
                                <mat-option value="active">Active</mat-option>
                                <mat-option value="blocked">Blocked</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('first_name')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>First name</mat-label>
                            <input matInput
                                   [formControlName]="'first_name'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('name')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Name</mat-label>
                            <input matInput
                                   [formControlName]="'name'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('network')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Network</mat-label>
                            <input matInput
                                   [formControlName]="'network'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('stype')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Type</mat-label>
                            <input matInput
                                   [formControlName]="'stype'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('sstatus')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Status</mat-label>
                            <input matInput
                                   [formControlName]="'sstatus'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('subtype')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Subtype</mat-label>
                            <input matInput
                                   [formControlName]="'subtype'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('value')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Value</mat-label>
                            <input matInput
                                   [formControlName]="'value'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('username')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Username</mat-label>
                            <input matInput
                                   [formControlName]="'username'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('message')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Message</mat-label>
                            <input matInput
                                   [formControlName]="'message'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('channel')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Channel</mat-label>
                            <input matInput
                                   [formControlName]="'channel'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('level_name')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Level Name</mat-label>
                            <input matInput
                                   [formControlName]="'level_name'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('url')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>URL</mat-label>
                            <input matInput
                                   [formControlName]="'url'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('last_name')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Last name</mat-label>
                            <input matInput
                                   [formControlName]="'last_name'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('email')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Email</mat-label>
                            <input matInput
                                   [formControlName]="'email'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('phone')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Phone</mat-label>
                            <input matInput
                                   [formControlName]="'phone'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('balance')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Balance</mat-label>
                            <input matInput
                                   [formControlName]="'balance'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('total')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Total</mat-label>
                            <input matInput
                                   [formControlName]="'total'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('domain')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Domain</mat-label>
                            <input matInput
                                   [formControlName]="'domain'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('slug')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Slug</mat-label>
                            <input matInput
                                   [formControlName]="'slug'">
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('type')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <app-custom-select [selectForm]="filterForm"
                                           [formField]="'type'"
                                           [label]="'Type'"
                                           [items]="types" [multi]="false">
                        </app-custom-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('status')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <app-custom-select [selectForm]="filterForm"
                                           [formField]="'status'"
                                           [label]="'Status'"
                                           [items]="statuses" [multi]="false">
                        </app-custom-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('is_recurring')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Type</mat-label>
                            <mat-select [formControlName]="'is_recurring'" placeholder="Select recurring">
                                <mat-option value="1">Yes</mat-option>
                                <mat-option value="0">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('method')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Type</mat-label>
                            <mat-select [formControlName]="'method'" placeholder="Select method">
                                <mat-option value="post">post</mat-option>
                                <mat-option value="get">get</mat-option>
                                <mat-option value="delete">delete</mat-option>
                                <mat-option value="patch">patch</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('status_exception')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Status</mat-label>
                            <mat-select [formControlName]="'status_exception'" placeholder="Select status">
                                <mat-option value="">All</mat-option>
                                <mat-option value="new">New</mat-option>
                                <mat-option value="resolved">Resolved</mat-option>
                                <mat-option value="muted">Muted</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('online_at')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Online</mat-label>
                            <input matInput
                                   [matDatepicker]="datepickeronline"
                                   [formControlName]="'online_at'">
                            <mat-datepicker-toggle matSuffix
                                                   [for]="datepickeronline">
                            </mat-datepicker-toggle>
                            <mat-datepicker #datepickeronline></mat-datepicker>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('created_at')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Created</mat-label>
                            <input matInput
                                   [matDatepicker]="datepickercreated"
                                   [formControlName]="'created_at'">
                            <mat-datepicker-toggle matSuffix
                                                   [for]="datepickercreated">
                            </mat-datepicker-toggle>
                            <mat-datepicker #datepickercreated></mat-datepicker>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('updated_at')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Updated</mat-label>
                            <input matInput
                                   [matDatepicker]="datepickerupdated"
                                   [formControlName]="'updated_at'">
                            <mat-datepicker-toggle matSuffix
                                                   [for]="datepickerupdated">
                            </mat-datepicker-toggle>
                            <mat-datepicker #datepickerupdated></mat-datepicker>
                        </mat-form-field>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterForm.get('next_payment_date')">
                    <div class="flex w-1/4 pr-6 xs:min-w-full">
                        <mat-form-field class="w-full">
                            <mat-label>Next payment date</mat-label>
                            <input matInput
                                   [matDatepicker]="datepicker1"
                                   [formControlName]="'next_payment_date'">
                            <mat-datepicker-toggle matSuffix
                                                   [for]="datepicker1">
                            </mat-datepicker-toggle>
                            <mat-datepicker #datepicker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
            <div class="button-group flex flex-auto pr-6 xs:min-w-full">
                <button class="open-filter mr-2"
                        mat-flat-button
                        [color]="'primary'"
                        (click)="search()">
                    Search
                </button>
                <button class="open-filter mr-2"
                        mat-flat-button
                        [color]="'accent'"
                        (click)="clearFilter()">
                    Clear filter
                </button>
                <button class="open-filter mr-2"
                        mat-flat-button
                        [color]="'warn'"
                        (click)="closeFilter()">
                    <span class="material-icons">
                        close
                    </span>
                </button>
            </div>
        </div>

    </form>
</div>
