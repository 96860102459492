import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable()
// @ts-ignore
export class RouteUtilsService {

    constructor(public _router: Router,
                public _route: ActivatedRoute) {
    }

    back(returnUrl: string = null): void {
        if (!returnUrl) {
            return;
        }
        this.navigate(returnUrl);
    }

    navigate(returnUrl: string): void {
        const splitUrls = returnUrl.split('?');
        const url = splitUrls[0];
        const paramsString = splitUrls[1];
        const params = {};
        if (paramsString) {
            const paramsArray = splitUrls[1].split('&');
            paramsArray.map(res => {
                const oneParams = res.split('=');

                if (oneParams[0] === 'returnUrl') {
                    oneParams[1] = oneParams[1].split('%2F').join('/');
                    if (oneParams[1].includes('%3F')) {
                        oneParams[1] = oneParams[1].split('%3F')[0];
                    }
                }
                params[oneParams[0]] = oneParams[1];
            });
        }
        if (params && Object.keys(params).length !== 0) {
            this._router.navigate([url], {queryParams: params});
        } else {
            this._router.navigateByUrl(url);
        }
    }

}
