import {UserService} from './layout/common/user/user.service';
import {defaultNavigation, horizontalNavigation} from './core/config/app.config';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any> {

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param _userService
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Load user
     *
     * @private
     */
    private _loadUser(): Observable<any> {
        return this._userService.loadUser();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return forkJoin([
            // User
            this._loadUser()
        ]).pipe(
            map(() => {
                return {
                    navigation: {
                        default: defaultNavigation,
                        horizontal: horizontalNavigation
                    }
                };
            })
        );
    }
}
