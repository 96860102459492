import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {CommonModule} from '@angular/common';
import {
    NGX_MAT_DATE_FORMATS, NgxMatDateFormats,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {DateTimePickerComponent} from './date-time-picker.component';
import {NgxMatMomentModule} from '@angular-material-components/moment-adapter';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: 'YYYY-MM-DD hh:mm:ss'
    },
    display: {
        dateInput: 'YYYY-MM-DD hh:mm:ss',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

@NgModule({
    declarations: [
        DateTimePickerComponent
    ],
    imports: [
        CommonModule,
        MatDatepickerModule,
        MatInputModule,
        NgxMatTimepickerModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        NgxMatDatetimePickerModule,
        NgxMatMomentModule
    ],
    providers: [
        {provide: NGX_MAT_DATE_FORMATS,
            useValue: CUSTOM_DATE_FORMATS}
    ],
    exports: [
        DateTimePickerComponent
    ]
})
export class DateTimePickerModule {
}
