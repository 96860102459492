<treo-vertical-navigation class="bg-cool-gray-900 theme-dark"
                          *ngIf="isScreenSmall"
                          [appearance]="'classic'"
                          [mode]="'over'"
                          [name]="'mainNavigation'"
                          [navigation]="data.navigation.horizontal"
                          [opened]="false">

    <div treoVerticalNavigationContentHeader>
        <div>
            <img src="assets/images/logo/logo_transparent.png">
        </div>
    </div>

</treo-vertical-navigation>
<!-- Wrapper -->
<div class="wrapper">

    <!-- Header -->
    <div class="header">

        <!-- Logo -->
        <div class="logo"
             *ngIf="!isScreenSmall">
            <img class="logo-text"
                 src="assets/images/logo/logo_transparent.png">
<!--            <img class="logo-text-on-dark"-->
<!--                 src="assets/images/logo/logo_transparent.png">-->
<!--            <img class="logo-base"-->
<!--                 src="assets/images/logo/logo_transparent.png">-->
        </div>

        <!-- Navigation toggle button -->
        <button class="navigation-toggle-button"
                *ngIf="isScreenSmall"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>

        <!-- Horizontal navigation -->
        <treo-horizontal-navigation
                *ngIf="!isScreenSmall"
                [name]="'mainNavigation'"
                [navigation]="data.navigation.horizontal"></treo-horizontal-navigation>

        <!-- Navigation toggle button -->

        <!-- Spacer -->
        <div class="spacer"></div>

        <!-- Notifications -->
        <notifications [notifications]="data.notifications"></notifications>

        <!-- User -->
        <user></user>

    </div>

    <!-- Content -->
    <div class="content">
        <mat-progress-bar [mode]="'indeterminate'" value="40" *ngIf="loading"></mat-progress-bar>
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

    <!-- Footer -->
    <div class="footer">
        Network &copy; {{currentYear}}
    </div>

</div>
